<template>
    <img alt="Alpha Labs Logo" src="@/assets/logo.png" height="64px">
    <h1>Alpha Labs</h1>
    <p>We are the codebreakers, the visionaries
of the digital age. In the shadows of the
digital realm, we rise as the architects of
tomorrow. We do not follow trends; we set
them. We don't just adapt; we innovate.
We defy the ordinary and embrace the
unknown. Art is our weapon of change,
technology is our compass. We collect the
rare, the wild, the extraordinary. We are
the degens, the early adopters, the
relentless experimenters. We are the
round pegs in square holes. We stand in
line not for the product, but for the
revolution it represents. We are the
misfits, the dreamers, the ones crazy
enough to believe we can change the
world.</p>

<p>We are Alpha Labs! Join Us!</p>
    <ButtonLayout>
        <BaseButton title="AlphaLabs.xyz" url="https://alphalabs.xyz" />
        <BaseButton title="Follow us on " url="https://x.com" >
            <svg height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" enable-background="new 0 0 24 24"><path d="M14.095 10.316 22.286 1h-1.94L13.23 9.088 7.551 1H1l8.59 12.231L1 23h1.94l7.51-8.543L16.45 23H23l-8.905-12.684zm-2.658 3.022-.872-1.218L3.64 2.432h2.98l5.59 7.821.869 1.219 7.265 10.166h-2.982l-5.926-8.3z" fill="#ebebeb" class="fill-000000"></path></svg>
        </BaseButton>
        <BaseButton title="Back" page-id="MainPage" />
    </ButtonLayout>
</template>