<template>
    <component :is="currentPage" @on-go-to="goToPage"/>
</template>

<script>
import pages from '@/pages.js';
import {provide} from 'vue';

const components = {};
pages.registerComponents(components);

export default {
    components,
    created() {
        provide('goToPage', (pageId) => this.currentPage = pageId);
    },
    data() {
        return {
            currentPage: pages.getEntryPage(),
        };
    }
}
</script>